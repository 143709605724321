const serverFetch = () => () => Promise.resolve();

const clientFetch = () => {
  // eslint-disable-next-line
  window.fetch = require('isomorphic-fetch');
  return window.fetch;
};

// eslint-disable-next-line
const fetch = global.window && global.window.document
  ? clientFetch()
  : serverFetch();

export default { fetch };
