import Request from './request';
import ServiceResultFactory from './ServiceResultFactory';

const getUserParticipation = ({
  baseApiUrl, eventEditionId, userId, locale
}) => {
  const query = `{
    userParticipation(eventEditionId: "${eventEditionId}", userId: "${userId}") 
    {
        exhibitingOrganisationId,
        participationId,
        isParticipationActive
    }
  }`;
  return Request.graphqlRequest(baseApiUrl, query)
    .then((response) => response.body)
    .then(ServiceResultFactory.fromSuccess)
    .catch((result) => ServiceResultFactory.fromErrorCode(locale, result, false));
};
const getEventEditionListForAdmin = (baseApiUrl, locale, eventEditionId) => {
  const query = `{
        userExhibitingOrganisations(eventEditionId: "${eventEditionId}", locale: "${locale}")
        { 
          eventEditionId, 
          exhibitingOrganisationId,
          package
        }
        }`;

  return Request.graphqlRequest(baseApiUrl, query)
    .then((response) => response.body)
    .then(ServiceResultFactory.fromSuccess)
    .catch((result) => ServiceResultFactory.fromErrorCode(locale, result, false));
};

export default {
  getUserParticipation,
  getEventEditionListForAdmin
};
