// eslint-disable-next-line import/no-cycle
import Utils from './Utils';

const awsConfig = {
  local: {
    assetSource: 'mediabox-dev-privates3bucket-rpcf485l0hns.s3.eu-west-1.amazonaws.com'
  },
  localAgainstDev: {
    assetSource: 'mediabox-dev-privates3bucket-rpcf485l0hns.s3.eu-west-1.amazonaws.com'
  },
  localAgainstDevPkce: {
    assetSource: 'mediabox-dev-privates3bucket-rpcf485l0hns.s3.eu-west-1.amazonaws.com'
  },
  localAgainstShow: {
    assetSource: 'mediabox-show-privates3bucket-1uymonb86s5bh.s3.eu-west-1.amazonaws.com'
  },
  localAgainstShowPkce: {
    assetSource: 'mediabox-show-privates3bucket-1uymonb86s5bh.s3.eu-west-1.amazonaws.com'
  },
  dev: {
    assetSource: 'mediabox-dev-privates3bucket-rpcf485l0hns.s3.eu-west-1.amazonaws.com'
  },
  perf: {
    assetSource: 'mediabox-perf-privates3bucket-130jwdzw6y45z.s3.eu-west-1.amazonaws.com'
  },
  show: {
    assetSource: 'mediabox-show-privates3bucket-1uymonb86s5bh.s3.eu-west-1.amazonaws.com'
  },
  preprod: {
    assetSource: 'mediabox-preprod-privates3bucket-f46gzip36c1w.s3.eu-west-1.amazonaws.com'
  },
  prod: {
    assetSource: 'mediabox-prod-privates3bucket-of4uq2zbtqd4.s3.eu-west-1.amazonaws.com'
  }
};
Object.freeze(awsConfig);

const getAssetSource = () => {
  const env = Utils.getCurrentEnvironment();
  const s3Source = env && awsConfig[env] ? awsConfig[env].assetSource
    : awsConfig.prod.assetSource;
  return `https://${s3Source}`;
};

export default {
  getAssetSource
};
