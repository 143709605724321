import Request from './request';
import ServiceResultFactory from './ServiceResultFactory';
import { Devices } from '../Constants';
import Utils from '../Utils';
import featureToggles from '../featureToggle/featureToggles';

const eventEditionUrl = (baseUrl, eventEditionId) => `${baseUrl}/v1/event-editions/${eventEditionId}`;

const getWithLocaleFallback = (url, { locale }) => Request.get(url, { locale })
  .catch(() => Request.get(url, { locale: 'en-GB' }));

const GetListOfSignedAssets = (responseBody) => {
  if (featureToggles.isFeatureEnabled(featureToggles.Keys.USE_NEW_SIGNEDASSETS_QUERY)) { return responseBody.signedAssets.items; }
  return responseBody.signedAssets;
};

const getSignedAssetGraphql = (baseApiUrl, query, locale) => Request.graphqlRequest(baseApiUrl, query)
  .then(ServiceResultFactory.fromSuccess)
  .then((response) => GetListOfSignedAssets(response.body))
  .catch((result) => ServiceResultFactory.fromGraphQlError(locale, result.errors));

const getEventEditionProperties = (baseApiUrl, eventEditionId, locale) => {
  const query = `{
      eventEdition(eventEditionId:"${eventEditionId}")
      {
        isParticipationEvent, isAnonymousRegistrationEnabled, 
        exhibitorInsightsConfig
        {
          enableDashboard
        } 
      }
    }`;
  return Request.graphqlRequest(baseApiUrl, query)
    .then((response) => ServiceResultFactory.fromSuccess(response.body))
    .catch((result) => ServiceResultFactory.fromGraphQlError(locale, result.errors));
};

const getSignedAsset = (baseApiUrl, assetKey, locale) => {
  // .replace('\'', 'A');//.replace(/\\'/, '');
  const desktopSignedAssetKey = assetKey.replace('/photo/', `/photo/${Devices.desktop}/`);
  const mobileSignedAssetKey = assetKey.replace('/photo/', `/photo/${Devices.mobile}/`);
  let query = `{
        signedAssets(keys: ["${desktopSignedAssetKey}", "${mobileSignedAssetKey}"])
        {
          url, key 
        }
      }`;
  if (featureToggles.isFeatureEnabled(featureToggles.Keys.USE_NEW_SIGNEDASSETS_QUERY)) {
    query = `{
    signedAssets(keys: ["${desktopSignedAssetKey}", "${mobileSignedAssetKey}"])
    {
      items { url, key }
    }
  }`;
  }
  return getSignedAssetGraphql(baseApiUrl, query, locale);
};

const getSignedAssetForMultipleAssetKeys = (baseApiUrl, assetKeys, locale) => {
  let photoKeyList = '';
  assetKeys.forEach((assetKey) => {
    if (assetKey && assetKey !== '') {
      photoKeyList += `"${assetKey.replace('/photo/', `/photo/${Devices.desktop}/`)}",
      "${assetKey.replace('/photo/', `/photo/${Devices.mobile}/`)}",`;
    }
  });
  let query = `{
        signedAssets(keys: [${photoKeyList}])
        {
          url, key 
        }
      }`;
  if (featureToggles.isFeatureEnabled(featureToggles.Keys.USE_NEW_SIGNEDASSETS_QUERY)) {
    query = `{
    signedAssets(keys: [${photoKeyList}])
    {
      items { url, key }
    }
  }`;
  }
  return getSignedAssetGraphql(baseApiUrl, query, locale);
};

const getParticipantData = (baseApiUrl, photoKey, context, participantData, baseImageUrl) => getSignedAssetForMultipleAssetKeys(
  baseApiUrl,
  photoKey,
  context.interfaceLocale
)
  .then((response) => {
    response.forEach((res) => {
      participantData.forEach((par) => {
        if (res.url.length === 0) {
          return;
        }
        if (res.key.includes(`${par.participantId}/photo/${Devices.desktop}`)) {
          par.desktopUrl = Utils.addDomainPrefix(res.url, baseImageUrl);
        }
        if (res.key.includes(`${par.participantId}/photo/${Devices.mobile}`)) {
          par.mobileUrl = Utils.addDomainPrefix(res.url, baseImageUrl);
        }
      });
    });
    return participantData;
  });

const getParticipantsWithPhotoUrls = async (participantData, baseApiUrl, context, baseImageUrl) => {
  const photoKey = [];
  /* eslint-disable no-param-reassign */
  participantData.forEach((data) => {
    data.desktopUrl = '';
    data.mobileUrl = '';
    if (data.photoKey) { photoKey.push(data.photoKey); }
  });

  if (Array.isArray(photoKey) && photoKey.length) {
    return getParticipantData(baseApiUrl, photoKey, context, participantData, baseImageUrl);
  }
  return participantData;
};

const getEventEdition = (baseUrl, eventEditionId, locale) => {
  const url = eventEditionUrl(baseUrl, eventEditionId);
  return Request.get(url)
    .then((response) => ServiceResultFactory.fromSuccess(response.body))
    .catch((error) => ServiceResultFactory.fromError(locale, error));
};

export default {
  getWithLocaleFallback,
  getSignedAsset,
  getParticipantsWithPhotoUrls,
  getEventEdition,
  getEventEditionProperties
};
