import { authController } from '@reedexpo/authorization-component-ui';
import jsonwebtoken from 'jsonwebtoken';
import { Claims } from '../Constants';

const authCookieName = 'RX_AUTH';
const clientIdCookieName = 'ClientId';

const usePkce = () => (typeof (window) === 'undefined'
  ? false
  : (window && window.authSettings && window.authSettings.usePkce));

const getCookies = () => {
  const cookies = {};
  if (typeof (window) === 'undefined') {
    return {};
  }
  document.cookie.split('; ').forEach((cookie) => {
    const [name, value] = cookie.split('=');
    cookies[name] = decodeURIComponent(value);
  });
  return cookies;
};

const getToken = () => {
  if (usePkce()) {
    return authController.getToken()
      .then((t) => t)
      .catch(() => null);
  }
  return Promise.resolve(getCookies()[authCookieName] || null);
};

const hasClaim = async (allowAdmins, claimName, claimValue) => {
  if (usePkce()) {
    const hasClaimValue = await authController.hasClaim(allowAdmins, claimName, claimValue);
    return hasClaimValue;
  }
  const token = await getToken();
  if (!token) {
    return false;
  }
  try {
    const decodedToken = jsonwebtoken.decode(token);
    return decodedToken[claimName] === claimValue || decodedToken[claimName].includes(claimValue);
  } catch (e) {
    return false;
  }
};

const getClientId = () => getCookies()[clientIdCookieName] || null;

const hasAdminClaim = (token) => {
  if (!token) {
    return false;
  }
  try {
    const decodedToken = jsonwebtoken.decode(token);
    return !!decodedToken[Claims.RxOrg];
  } catch (e) {
    return false;
  }
};

const getCurrentLoggedInParticipantId = (token) => {
  if (!token) {
    return '';
  }
  try {
    const decodedToken = jsonwebtoken.decode(token);
    return Claims.RxParticipant in decodedToken ? decodedToken[Claims.RxParticipant] : '';
  } catch (e) {
    return '';
  }
};

const getCurrentLoggedInRxOrgId = (token) => {
  if (!token) {
    return '';
  }
  try {
    const decodedToken = jsonwebtoken.decode(token);
    return Claims.RxOrg in decodedToken ? decodedToken[Claims.RxOrg] : '';
  } catch (e) {
    return '';
  }
};

export default {
  hasClaim,
  getToken,
  getClientId,
  hasAdminClaim,
  getCurrentLoggedInParticipantId,
  getCurrentLoggedInRxOrgId
};
