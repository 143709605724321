import LoggerFactory from '@reedexpo/ui-logger';
import authorizationStore from './common/api/authorizationStore';
import Utils from './common/Utils';

let loggerInstance;

const initialiseLogger = (baseApiUrl) => {
  const service = 'Partybox-ui';
  const version = '0.1';
  const apigeeClientId = authorizationStore.getClientId();
  const loggingUrl = baseApiUrl ? `${baseApiUrl}/log` : `${Utils.getReactSettings().props?.baseApiUrl}/log`;
  const url = Utils.removeDuplicateSlashInUrl(loggingUrl);

  return LoggerFactory.configure({
    url, service, version, apigeeClientId
  });
};

const getLogger = (baseApiUrl) => {
  if (!loggerInstance) {
    loggerInstance = initialiseLogger(baseApiUrl);
  }
  return loggerInstance;
};

const extractErrors = (error) => {
  if (error instanceof Error) {
    return error.toString();
  }

  let errorSource;
  if (error.body && error.body.errors) { errorSource = error.body.errors; }
  if (error.errors) { errorSource = error.errors; }
  if (!errorSource) { return error.toString(); }
  return errorSource.map((err) => {
    if (err.code) { return `${err.code}${err.message ? `: ${err.message}` : ''}`; }
    return JSON.stringify(err);
  }).join(', ');
};

const logError = (message, error, baseApiUrl) => {
  const logger = getLogger(baseApiUrl);
  const extractedErrors = error ? extractErrors(error) : '';
  logger.error({ message }, extractedErrors);
};

const logInfo = (message, error, baseApiUrl) => {
  const logger = getLogger(baseApiUrl);
  const extractedErrors = error ? extractErrors(error) : '';
  logger.info({ message }, extractedErrors);
};

const logWarn = (message, error, baseApiUrl) => {
  const logger = getLogger(baseApiUrl);
  const extractedErrors = error ? extractErrors(error) : '';
  logger.warn({ message }, extractedErrors);
};

export default {
  logError,
  logInfo,
  logWarn,
  getLogger
};
