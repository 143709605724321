const bootstrap = {
  alert: 'alert',
  alertWarning: 'alert-warning',
  button: 'btn',
  buttonGroup: 'btn-group',
  centerBlock: 'center-block',
  danger: 'danger',
  default: 'default',
  defaultLabel: 'label label-default',
  error: 'error',
  formInline: 'form-inline',
  h4: 'h4',
  hasError: 'has-error',
  hide: 'hide',
  inlineBlock: 'inline-block',
  lightButton: 'btn-light',
  linkButton: 'link-button',
  primary: 'primary',
  primaryButton: 'btn btn-primary panel-primary',
  pullLeft: 'pull-left',
  pullRight: 'pull-right',
  pullRightLarge: 'pull-right-lg',
  relative: 'relative',
  secondaryButton: 'btn btn-default',
  secondaryButton2: 'btn-secondary-02',
  success: 'success',
  textCenter: 'text-center',
  textDanger: 'text-danger',
  textLeft: 'text-left',
  textRight: 'text-right',
  warning: 'warning',
  well: 'well',
  wrapWord: 'wrap-word',
  formFieldSwitchGroup: 'form-field-switch-group'
};

const spinner = {
  root: 'spinner',
  bigSpinner: 'big-spinner',
  message: 'spinner-message',
  stretchableSpinner: 'stretchable-spinner'
};

const circleSpinner = {
  c1: 'circle1',
  c2: 'circle2',
  c3: 'circle3',
  c4: 'circle4',
  c5: 'circle5',
  c6: 'circle6',
  c7: 'circle7',
  c8: 'circle8',
  c9: 'circle9',
  c10: 'circle10',
  c11: 'circle11',
  c12: 'circle12',
  circle: 'circle',
  container: 'spinner-container',
  spinnerWrapper: 'spinner-wrapper',
  spinnerWrapperBig: 'spinner-wrapper_big',
  spinnerWrapperFixed: 'spinner-wrapper_fixed',
  wrapper: 'circle-wrapper'
};

const forms = {
  formGroupMultiSelect: 'form-group-multi-select',
  formGroupViewMode: 'form-group-view-mode',
  formGroupViewModeClickable: 'form-group-view-mode-clickable',
  formGroupViewModeInline: 'form-group-view-mode-inline',
  formGroupViewModeMultiline: 'form-group-view-mode-multiline',
  formViewModeSection: 'form-view-mode-section',
  well: 'well',
  formRow: 'form-row',
  formGroup: 'form-group',
  formControl: 'form-control',
  description: 'description',
  submit: 'submit',
  submitting: 'submitting',
  types: {
    text: 'text',
    button: 'button'
  },
  inputContainer: 'input-container'
};

const icons = {
  iconContainer: 'icon-container',
  add: 'fa fa-plus',
  arrowDown: 'fa fa-angle-down',
  arrowUp: 'fa fa-angle-up',
  checked: 'fa fa-check-square',
  clock: 'fa fa-clock-o',
  delete: 'fa fa-trash',
  document: 'fa fa-file-o',
  download: 'fa fa-arrow-circle-o-down',
  edit: 'fa fa-pencil',
  email: 'fa fa-envelope-o',
  favouritePosition: 'favourite-position',
  favouritePositionTablet: 'favourite-position-tablet',
  filter: 'fa fa-filter',
  iconSpaceLeft: 'icon-space-left',
  iconSpaceRight: 'icon-space-right',
  large: 'fa-lg',
  location: 'fa fa-map-marker',
  noSelection: 'no-selection',
  phone: 'fa fa-phone',
  unchecked: 'fa fa-square-o',
  upload: 'fa fa-photo',
  website: 'fa fa-globe',
  uploadDocument: 'fa fa-upload',
  marker: 'fa fa-map-marker',
  search: 'fa fa-search',
  chat: 'fa fa-comments',
  adminWarning: 'fa fa-exclamation-triangle fa-fw'
};

const switchControl = {
  slider: 'slider round',
  sliderSmall: 'slider-small',
  slidingPanel: 'sliding-panel',
  slidingPanelSmall: 'sliding-panel-small',
  switch: 'switch',
  switchCheckbox: 'switch-checkbox',
  switchInner: 'switch-inner',
  switchInnerAfter: 'switch-inner-after',
  switchInnerBefore: 'switch-inner-before',
  switchLabel: 'switch-label',
  switchSmall: 'switch-small',
  message: 'message',
  topMargin: 'top-margin'
};

const selenium = {
  dataLoaded: 'data-loaded'
};

const favouriteElement = {
  favouriteParticipantIcon: 'favourite-participant-icon',
  favSelectFill: 'showplan--svg-fill-icon__btnFontColor',
  favUnselectFill: 'favourite-unselect-fill-opacity',
  favouriteIconImg: 'favourite-icon-img',
  favouriteIconContainer: 'favourite-icon-container',
  iconContainer: 'icon-container',
  networkParticipantIcon: 'network-participant-icon',
  networkIconContainer: 'network-icon-container',
  networkingIconFill: 'showplan--svg-fill-icon__btnFontColor',
  networkingIcon: 'networking-icon',
  networkIconProfile: 'network-icon-profile'
};

const profileCompletionPercentage = {
  sizingSmall: 'percentage-size-small',
  sizingLarge: 'percentage-size-large'
};

const exclamation = {
  exclamationSmall: 'fa fa-triangle-exclamation small',
  exclamationLarge: 'fa fa-triangle-exclamation large'
};

const companyCompletionBuildingIcon = 'fa fa-solid fa-building large';

const ppsMetrics = 'pps-metrics';
const networkAttendeesCount = 'network-attendees-count';

const homePage = {
  rootElement: 'participant-directory',
  search: {
    resultCountRoot: 'result-count'
  },
  directory: {
    listContainer: 'list-container',
    initialLoading: 'initial-loading',
    settings: {
      root: 'logged-in-user-settings',
      setting: 'setting',
      editProfile: 'edit-profile',
      editProfileIcon: 'fa fa-user',
      editCompanyProfile: 'edit-company-profile',
      editCompanyProfileIcon: 'fa fa-building',
      missingProfileImageIcon: 'fa fa-info-circle',
      logout: 'logout',
      logoutIcon: 'fa fa-sign-out',
      onlineDirectory: 'online-directory',
      directoryIcon: 'fa fa-chevron-left',
      spinner: 'logged-in-user-spinner',
      base: 'logged-in-user-settings__base',
      dropdownBase: 'logged-in-user-settings__dropdown-base',
      navbarList: 'settings-navbar-list',
      welcome: 'logged-in-user-settings__welcome',
      list: 'logged-in-user-settings__list',
      dropdownList: 'logged-in-user-settings__dropdown-list',
      item: 'logged-in-user-settings__item',
      dropdownItem: 'logged-in-user-settings__dropdown-item',
      link: 'logged-in-user-settings__link',
      dropdownLink: 'logged-in-user-settings__dropdown-link',
      label: 'logged-in-user-settings__label',
      dropdownLabel: 'logged-in-user-settings__dropdown-label',
      exHubIcon: 'fa fa-th',
      companyDashboardIcon: 'fas fa-tachometer-alt-fast action-item-icon',
      empLeadsIcon: 'empIcon',
      participantThumbnail: 'participant-dropdown-image',
      participantName: 'participant-dropdown-text',
      participantDropdownContainer: 'participant-dropdown-container',
      listHide: 'logged-in-user-settings__dropdown-list__hide',
      dropdown: 'settings-dropdown',
      topBorder: 'top-round-border',
      downArrow: 'down-arrow-settings-dropdown',
      upArrow: 'up-arrow-settings-dropdown'
    }
  },

  featureFilters: {
    root: 'feature-filters'
  },
  participantList: {
    activationEmail: 'activation-email',
    activationEmailButton: 'activation-email-button btn-border-radius',
    cancelButton: 'cancel-email-button btn-border-radius',
    searchBar: 'search-bar clear-fix btn-border-radius',
    searchButton: 'search-button btn btn-primary btn-border-radius',
    activationEmailCollapse: 'activation-email-collapse',
    contactDetailsToggle: 'hide-contact-details-toggle',
    contactDetailsToggleButton: 'hide-contact-details-toggle-button btn-border-radius',
    participantReportDownloadButton: 'participant_report_donwload_button'
  },

  participantItem: {
    root: 'participant-item btn-border-radius',
    thumbnail: 'participant-image left',
    details: 'participant-details',
    name: 'participant-name text-primary',
    jobTitle: 'participant-job-title',
    organization: 'participant-org',
    country: 'participant-country',
    createdDate: 'participant-date text-primary',
    updatedDate: 'participant-date text-primary',
    createdDateOn: 'participant-date-on',
    updatedDateOn: 'participant-date-on',
    dateComponentClass: 'date-component',
    participantWrapper: 'participant-wrapper',
    participantDetailsIconContactContainer: 'participant-details-icon-contact-container',
    participantDetailsIconContainer: 'participant-details-icon-container',
    participantContactDetails: 'participant-contact-details',
    participantPpsListWrapper: 'participant-pps-list-wrapper',
    aboutMeTextSection: 'about-me-text',
    aboutMeTitle: 'about-me-title'
  },
  participantProfile: {
    root: 'participant-profile-page participant-profile',
    participantProfile: 'participant-profile-content',
    participantBanner: 'participant-banner',
    profileContainer: 'participant-profile-container',
    participantName: 'participant-name',
    participantJob: 'participant-job',
    participantOrg: 'participant-org',
    participantMoreInfoContainer: 'participant-more-info-container',
    participantDescriptionContainer: 'participant-description-container',
    participantNameAndRoleContainer: 'participant-name-and-role-container',
    showMoreLessContainer: 'show-more-show-less-container',
    descriptionContainer: 'description-container',
    participantContact: 'participant-contact',
    resendEmailButton: 'resend-email-button',
    participantChat: 'particpant-chat',
    contact: {
      items: 'contact-items',
      item: 'contact-item',
      label: 'label',
      value: 'value',
      colored: 'colored',
      social: 'social',
      fb: 'facebook',
      fbIcon: 'fa fa-facebook',
      eyeIcon: 'eye-icon',
      optInContent: 'optin-content',
      optInChangeLink: 'optin-change-link',
      email: 'fa fa-envelope-o fa-lg fa-fw',
      phone: 'fa fa-phone fa-lg fa-fw',
      copy: 'fa fa-copy fa-lg fa-fw fa-flip-horizontal',
      clipBoard: 'fa fa-clone fa-xs fa-fw',
      chat: 'fa fa-comments fa-lg fa-fw'
    },
    contactForm: {
      root: 'participant-contact-form'
    },
    participantSettings: 'participant-settings',
    defaultProfileImage: 'default-profile-image'
  }
};

const featureFilterLabelList = {
  featureLabelList: 'feature-filter-label-list',
  featureLabel: 'feature-filter-label'
};

const common = {
  emptyContent: 'empty-content',
  borderRadius: 'btn-border-radius',
  clearFix: 'clear-fix',
  left: 'left',
  right: 'right',
  imageContainer: 'image-container',
  buttonContainer: 'button-container',
  showMoreLess: 'show-more-less',
  tagComponent: 'tag-component',
  tagList: 'tag-list participants',
  editable: 'editable',
  editMode: 'edit-mode',
  fileUploadWrapper: 'file-upload-wrapper',
  fileUpload: 'file-upload',
  defaultUploadIcon: 'fa fa-upload',
  deleteIcon: 'fa fa-trash',
  editIcon: 'btn-primary icon-button overlay-icon btn-secondary-02 btn edit-icon btn-border-radius',
  themeColor: 'text-primary',
  greyedOut: 'greyed-out',
  themeBg: 'bg-primary',
  themeButtonColor: 'btn-primary',
  themeBgColor: 'theme__ac-color--background-color',
  label: 'label',
  content: 'content',
  editableDescription: 'editable-description',
  ProfileHeader: 'profile-header',
  socialMediaLogoColor: {
    FACEBOOK: 'facebook-logo-color',
    TWITTER: 'twitter-logo-color',
    YOUTUBE: 'youtube-logo-color',
    LINKEDIN: 'linkedin-logo-color',
    WECHAT: 'wechat-logo-color'
  },
  stand: {
    root: 'stand',
    standName: 'stand-name',
    hiphen: 'hiphen'
  },
  prefix: 'prefix',
  withOpacity: 'with-opacity',
  withoutOpacity: 'without-opacity',
  imageHelpText: 'image-help-text',
  helpBlock: 'help-block',
  editableContainer: 'editable-container',
  editableMatchmakingQuestions: 'editable-matchmaking-questions',
  warningBox: 'warning-container',
  readOnlyContainer: 'read-only-container',
  separatedListItem: 'separated-list-item',
  centerFlex: 'center-flex',
  listNoStyle: 'list-no-style',
  manageFiltersContent: 'manage-filters-content',
  topBottomPadded: 'top-bottom-padded',
  flexItemRight: 'flex-item-right',
  secondary02Button: 'btn-secondary-02',
  roundAllCorners: 'round-all-corners',
  copyComponent: {
    customOverlayToolTip: 'custom-overlay-tooltip'
  },
  warningMessage: 'warning-message',
  errorMessageLink: 'error-message-link underline',
  profileImageError: 'profile-image-error',
  imageContainerHighlight: 'image-container-highlight',
  particiantBannerHighlight: 'participant-banner-highlight',
  particiantBannerHighlightText: 'participant-banner-highlight-text'
};

const socialMedia = {
  social: 'social',
  readOnly: 'social-media read-only'
};

const surveyPopup = {
  surveyFeedbackContainer: 'survey-feedback-container',
  surveyFeedbackContent: 'survey-feedback-content',
  surveyFeedbackclose: 'survey-feedback-close',
  surveyFeedbackcloseIcon: 'fal fa-times survey-feedback-close-icon',
  surveyLoadingCircle: 'survey-loading-circle',
  surveySpinnerContainer: 'survey-spinner-container'
};

const feedBackButton = {
  xploreFeedback: 'xplore-feedback',
  xploreFeedbackButton: 'btn btn-primary xplore-feedback-button'
};

const chatElement = {
  chatIconContainer: 'chat-icon-container',
  ChatParticipantDiv: 'chat-participant-div'
};

const Classes = {
  icons,
  spinner,
  circleSpinner,
  ...featureFilterLabelList,
  ...bootstrap,
  ...icons,
  ...forms,
  ...switchControl,
  ...selenium,
  profileCompletionPercentage,
  exclamation,
  ppsMetrics,
  homePage,
  ...common,
  socialMedia,
  ...favouriteElement,
  chatElement,
  surveyPopup,
  feedBackButton,
  networkAttendeesCount,
  companyCompletionBuildingIcon
};

export default Classes;
